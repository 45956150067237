*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

p {
  text-wrap: pretty;
}

h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
}

:root {
  --primary: #3d3e92;
}

nav {
  color: var(--primary);
  padding: 20px;
  font-size: 18px;
}

nav .right {
  float: right;
  display: inline-block;
}

nav a {
  color: var(--primary);
  margin: 0 10px;
  text-decoration: none;
  transition: all .2s ease-in-out;
}

nav a:hover {
  border-bottom: 3px solid var(--primary);
}

@media (width <= 600px) {
  nav {
    text-align: center;
  }

  nav .logo {
    margin-bottom: 10px;
    font-size: 25px;
    display: block;
  }

  nav .right {
    float: none;
    width: 100%;
    display: block;
  }
}

.mystery {
  color: var(--primary);
  text-align: center;
  margin-top: 100px;
  padding-bottom: 100px;
}

.mystery img {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.button {
  background-color: var(--primary);
  color: #fff;
  border: 3px solid var(--primary);
  border-radius: 8px;
  margin: 10px;
  padding: 10px 30px 15px;
  font-size: 20px;
  line-height: 1;
  transition: all .2s ease-in-out;
}

.button:hover, .button.alt {
  color: var(--primary);
  background-color: #fff;
}

.button.alt:hover {
  color: #fff;
  background-color: var(--primary);
}

.toast {
  text-align: center;
  width: 100%;
  transition: all .2s ease-in-out;
  position: fixed;
  bottom: 20px;
  left: 0;
  transform: translateY(300%);
}

.toast.show {
  display: block;
  transform: translateY(-100%);
}

.ca_toast_text {
  color: var(--primary);
  opacity: .7;
  border: 1px solid #a2a2a2;
  border-radius: 8px;
  padding: 10px 20px 15px;
  box-shadow: 0 0 20px #0000001a;
}
/*# sourceMappingURL=index.e0e10f37.css.map */
