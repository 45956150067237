*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
p {
  text-wrap: pretty;
}
h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
}

:root {
  --primary: #3d3e92;
}

nav {
  padding: 20px;
  color: var(--primary);
  font-size: 18px;
}

nav .right {
  display: inline-block;
  float: right;
}

nav a {
  transition: all ease-in-out 0.2s;
  margin: 0 10px;
  color: var(--primary);
  text-decoration: none;
}

nav a:hover {
  border-bottom: 3px solid var(--primary);
}

@media (max-width: 600px) {
  nav {
    text-align: center;
  }
  nav .logo {
    display: block;
    margin-bottom: 10px;
    font-size: 25px;
  }
  nav .right {
    float: none;
    display: block;
    width: 100%;
  }
}

.mystery {
  color: var(--primary);
  text-align: center;
  margin-top: 100px;
  padding-bottom: 100px;
}

.mystery img {
  margin: 0 auto;
  max-width: 450px;
  width: 100%;
}

.button {
  transition: all ease-in-out 0.2s;
  padding: 10px 30px 15px;
  background-color: var(--primary);
  color: white;
  font-size: 20px;
  border-radius: 8px;
  margin: 10px;
  border: 3px solid var(--primary);
  line-height: 1;
}

.button:hover {
  background-color: white;
  color: var(--primary);
}

.button.alt {
  color: var(--primary);
  background-color: white;
}

.button.alt:hover {
  color: white;
  background-color: var(--primary);
}

.toast {
  width: 100%;
  position: fixed;
  bottom: 20px;
  left: 0;
  text-align: center;
  transition: all ease-in-out 0.2s;
  transform: translateY(300%);
}

.toast.show {
  display: block;
  transform: translateY(-100%);
}

.ca_toast_text {
  padding: 10px 20px 15px 20px;
  border-radius: 8px;
  border: 1px solid #A2A2A2;
  color: var(--primary);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  opacity: 0.7;
}
